* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #ffffff;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
@media( min-width: 800px) {
  body{
    background-color: #ffffff;
    // display:none;
  }
}

::-webkit-scrollbar{
  width: 0px;
}
.MuiDialog-paper {
  margin:0px!important;
}
.MuiDialogContent-root{
  padding:8px 4px!important;
}
    